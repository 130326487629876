/* global
 autosize
 bsCustomFileInput
 checkRedirect
 gettext
 initHtmlTextarea
 $x
 */


// #############################################################################
// GLOBAL VARS

const $body = $("body");

// #############################################################################
// BETTER FOCUS

const $betterFocus = new $x.BetterFocus();
$betterFocus.init();

// #############################################################################
// TOOLTIP

$("[data-toggle=tooltip]").tooltip({ boundary: "window" });

// #############################################################################
// FORM: MULTIPLE SELECT ITEMS

$x.initMultipleSelectItems = () => {
  const $inputs = document.querySelectorAll("[data-multiple-select-item]");

  $inputs.forEach(function ($input) {
    if ($input.$multipleSelectItem) {
      return;
    }

    $input.$multipleSelectItem = new $x.MultipleSelectItems($input);
    $input.$multipleSelectItem.init();
  });
};

// #############################################################################
// FORM

$x.initFormDefaults = function ($parent = $body) {
  // File
  // TODO: Selber schreiben!
  bsCustomFileInput.init();

  // Autosize
  autosize($("textarea", $parent));

  // HTML CKEditor
  initHtmlTextarea($parent);

  // Range
  $("[type=range]", $parent).formRange();

  // Ajax upload
  const $ajaxUpload = new $x.AjaxUpload($("[data-ajax-upload]", $parent), {
    onUploadCompleted: function ($upload, $data) {
      $x.replaceHtml($data);
    },
  });

  $ajaxUpload.init();

  // File tree
  $("[data-file-tree]", $parent).formFileTree();

  // Form set
  $("[data-form-set]", $parent).formSet();

  $x.initMultipleSelectItems();

  return {
    ajaxUpload: $ajaxUpload,
  };
};

const $formDefaults = $x.initFormDefaults();

// Validation

$("[data-form]").formValidation({
  beforeSubmit: function () {
    $formDefaults.ajaxUpload.reset();
  },
  afterSubmit: function ($xhr, $form, $data) {
    if ($data.submit === "success") {
      if ($data.redirect) {
        checkRedirect($data);
      } else {
        $x.replaceHtml($data);

        if ($data.toaster) {
          $body.toaster("updateToaster", $data.toaster);
        }
      }
    }
  },
});

// Wizard

$("[data-form-wizard]").formWizard();


// #############################################################################
// DATA TABLE

function datatable_data_to_json (data_dict) {
  const filter_data_dict = {};
  function generate_filter_data (data_dict, prefix) {
    prefix = typeof prefix !== "undefined" ? prefix : false;
    $.each(data_dict, function (key, val) {
      if (prefix) { key = `${prefix}[${key}]`; }
      if (Array.isArray(val)) {
        generate_filter_data(val, key);
      } else if (val instanceof Object) {
        generate_filter_data(val, key);
      } else {
        filter_data_dict[key] = String(val);
      }
    });
  }
  generate_filter_data(data_dict);
  return filter_data_dict;
}

const $dataTables = document.querySelectorAll("[data-table]");

$dataTables.forEach(function ($item) {
  $item.$datatable = new $x.DataTables($item, {
    api: function ($table, $api) {
      // API: https://datatables.net/reference/api/

      const $input = $table.querySelector("[data-multiple-select-item]");

      $api.on("draw", function () {
        $("[data-toggle=tooltip]", $table).tooltip({ boundary: "window" });

        if ($input) {
          $input.$multipleSelectItem.reset();
        }

        // post without dialog and reload table (keep paging/filter)
        $("[data-post-no-dialog]").on("click", function(e) {
          e.preventDefault();
          fetch(e.currentTarget.href, {
            method: "POST",
            headers: {
              "X-CSRFToken": getCSRFToken(),
            },
          })
            .then(response => response.json())
            .then(function (data) {
              // show toaster
              if (data.toaster) {
                $body.toaster("updateToaster", data.toaster);
              }
              $api.draw(false); // redraw table
            })
            .catch(function (error) {
              console.log(error);
            });
        });
      });

      $body.on("submit", "form.export-form", function (e) {
        const filter_data_dict = datatable_data_to_json($api.columns().ajax.params());
        const export_list_filter_data = $("input[name='export_list_filter_data']");

        if (!export_list_filter_data.length) {
          $("<input />").attr("type", "hidden")
            .attr("name", "export_list_filter_data")
            .attr("value", JSON.stringify(filter_data_dict))
            .appendTo($(this));
        }
        export_list_filter_data.val(JSON.stringify(filter_data_dict));
      });
    },
    customizeCSV: function (csv) {
      // For customization read https://datatables.net/reference/button/csv

      return csv;
    },
    rowGroupStartRender: function ($table, $rows, html) {
      if ($table.id === "teaching_assignments_table") {
        $rows.every(function (index, tableLoop, rowLoop) {
          if (rowLoop === 0) {
            const data = this.data();
            const number = $x.removeText(data.number);
            const units = Math.ceil(data.semester_hours * 15);
            const semester_hours = $x.removeText(data.semester_hours);
            const ects = $x.removeText(data.ects);
            const transmitted_tas = data.transmitted_tas;
            const pk = data.pk;

            html = "<div class=\"row\">";
            html += "<div class=\"col-6 col-sm-4 col-md-2 col-xl-auto my-1 my-xl-0\"><strong class=\"font-ubuntu-regular\">" + gettext("LVA") + "</strong><br>" + number + "</div>";
            html += "<div class=\"col-6 col-sm-4 col-md-2 col-xl-auto my-1 my-xl-0\"><strong class=\"font-ubuntu-regular\">" + gettext("Units") + "</strong><br>" + units + "</div>";
            html += "<div class=\"col-6 col-sm-4 col-md-2 col-xl-auto my-1 my-xl-0\"><strong class=\"font-ubuntu-regular\">" + gettext("Semester hours") + "</strong><br>" + semester_hours + "</div>";
            html += "<div class=\"col-6 col-sm-4 col-md-2 col-xl-auto my-1 my-xl-0\"><strong class=\"font-ubuntu-regular\">" + gettext("ECTS") + "</strong><br>" + ects + "</div>";
            html += "<div class=\"col-6 col-sm-4 col-md-2 col-xl-auto my-1 my-xl-0\"><strong class=\"font-ubuntu-regular\">" + gettext("Transmitted TAs") + "</strong><br><span class='tooltip-wide' data-toggle='tooltip' data-html='true' title='" + transmitted_tas + "'>";

            let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
            svg.setAttribute("class", "icon icon-dark");
            let use = document.createElementNS("http://www.w3.org/2000/svg", "use");
            use.setAttributeNS("http://www.w3.org/1999/xlink", "xlink:href", "/static/img/sprite.symbol.svg#information-outline");
            svg.appendChild(use);

            html += svg.outerHTML;
            html += "</span></div>";

            html += "<div class=\"col-6 col-sm-4 col-md-2 col-xl-auto my-1 my-xl-0 icon-table\"><strong class=\"font-ubuntu-regular\">" + gettext("Set as transmitted") + "</strong><br>";
            html += "<strong class=\"font-ubuntu-regular\">";
            html += '<a data-post-no-dialog class="d-block ml-auto" href="set/transmitted/' + pk + '/" title="' + gettext("Set as transmitted") + '">';

            svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
            svg.setAttribute("class", "icon icon-dark");
            use = document.createElementNS("http://www.w3.org/2000/svg", "use");
            use.setAttributeNS("http://www.w3.org/1999/xlink", "xlink:href", "/static/img/sprite.symbol.svg#file-refresh-outline");
            svg.appendChild(use);
            html += svg.outerHTML;

            html += "</a>";
            html += "</div>";

            html += "<div class=\"col-6 col-sm-4 col-md-2 col-xl-auto my-1 my-xl-0 icon-table\"><strong class=\"font-ubuntu-regular\">" + gettext("Recalculate quotas") + "</strong><br>";
            html += "<strong class=\"font-ubuntu-regular\">";
            html += '<a data-post-no-dialog class="d-block ml-auto" href="recalculate/' + pk + '/" title="' + gettext("Recalculate quotas") + '">';

            svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
            svg.setAttribute("class", "icon icon-dark");
            use = document.createElementNS("http://www.w3.org/2000/svg", "use");
            use.setAttributeNS("http://www.w3.org/1999/xlink", "xlink:href", "/static/img/sprite.symbol.svg#refresh");
            svg.appendChild(use);
            html += svg.outerHTML;

            html += "</a>";
            html += "</div>";
          }
        });
      }
      return html;
    },
  });
});

// #############################################################################
// FULL CALENDAR

function getFirstDayOfNextMonth(dateString) {
  // Parse the input date string
  const date = new Date(dateString);

  // Set the date to the first day of the next month
  let year = date.getFullYear();
  let month = date.getMonth() + 1; // Increment the month
  const day = date.getDate();

  // if already first day -> don't change date
  if (day === 1) {
    return dateString;
  }
  if (month > 11) {
    month = 0;
    year++;
  }

  // user hours=12 to ignore timezones
  const firstDayOfNextMonth = new Date(year, month, 1, 12);

  // Format the date as "YYYY-MM-DDTHH:MM:SS"
  return firstDayOfNextMonth.toISOString().split("T")[0] + "T00:00:00";
}

const $calendars = document.querySelectorAll("[data-calendar]");

// get saved date and vieType from localStorage
let savedDate = localStorage.getItem("savedDate");
let savedViewType = localStorage.getItem("savedViewType");

if (window.location.href.includes("ideal_calendar")) {
  savedDate = null;
  savedViewType = null;
}

// default viewType is dayGridMonth
if (!savedViewType) {
  savedViewType = "dayGridMonth";
}
// get first day for month-view
if (savedDate && savedViewType === "dayGridMonth") {
  savedDate = getFirstDayOfNextMonth(savedDate);
}

$calendars.forEach(function ($item) {
  $item.$calendar = new $x.FullCalendar($item, {
    initialDate: savedDate,
    initialView: savedViewType,
    datesSet: function (info) {
      // save date and viewType to reset it on reload in "normal" calendar
      if (!window.location.href.includes("ideal_calendar")) {
        localStorage.setItem("savedDate", info.startStr);
        localStorage.setItem("savedViewType", info.view.type);
      }
    },
    loading: function(isLoading) {
      const loading_button = $(".loading_button");
      if (isLoading) {
        // show loader
        loading_button.hide();
        $(".loader").show();
        $(".fc-list-empty-cushion").text("Termine werden geladen!");
      } else {
        // hide loader
        loading_button.show();
        $(".loader").hide();
        // if no data is found display message
        $(".fc-list-empty-cushion").text("Keine Termine gefunden");
      }
      $("[data-toggle=tooltip]").tooltip("dispose").tooltip({ boundary: "window" });
    },
    noEventsDidMount: function(el) {
      $(".fc-list-empty-cushion").text("Termine werden geladen!");
    },
    eventsSet: function(events) {
      if (this.el.baseURI.includes("ideal_calendar")) {
        const begin = calculate_week_number(this.currentData.dateProfile.activeRange.start);
        let end = new Date(this.currentData.dateProfile.activeRange.end);
        end = end.setDate(end.getDate() - 1);
        end = calculate_week_number(new Date(end));
        const week_string = "W ".concat(begin, "-", end);
        $(".fc-toolbar-title")[0].firstChild.data = week_string;
      }
      const module_list = [];
      events.forEach(function(val) {
        if (val.extendedProps.modules) {
          const module = val.extendedProps.modules;
          const split_values = module.split(",");
          split_values.forEach(function (split_value) {
            if (split_value && !module_list.includes(split_value)) {
              module_list.push(split_value);
            }
          });
        }
      });
      if (document.getElementById("calendar_module_display_span")) {
        document.getElementById("calendar_module_display_span").textContent = "";
        document.getElementById("calendar_module_tooltip").dataset.originalTitle = "";
        document.getElementById("calendar_module_tooltip").classList.remove("d-flex");
        const calendar_module_tooltip = document.getElementById("calendar_module_tooltip");
        calendar_module_tooltip.setAttribute("hidden", "hidden");
        if (module_list.length > 0) {
          document.getElementById("calendar_module_tooltip").classList.add("d-flex");
          for (let i = 0; i < module_list.length; i++) {
            if (i === 0) {
              document.getElementById("calendar_module_display_span").textContent += module_list[i];
            } else if (i === 1) {
              document.getElementById("calendar_module_display_span").textContent += ", " + module_list[i];
            } else {
              const strong = document.createElement("strong");
              strong.textContent = module_list[i];
              calendar_module_tooltip.removeAttribute("hidden"); // show "+"-tooltip
              calendar_module_tooltip.dataset.originalTitle += strong.outerHTML + "<br>";
            }
          }
        }
      }
      $("[data-toggle=tooltip]").tooltip("dispose").tooltip({ boundary: "window" });
      // little hack to remove title-attribute because of ideal-calendar (no "real" dates)
      $(".fc-daygrid-day-number").each(function() { $(this).attr("title", ""); });
      $(".fc-col-header-cell-cushion").each(function() { $(this).attr("title", ""); });
      $(".fc-list-day-side-text").each(function() { $(this).attr("title", ""); });
      $(".fc-list-day-text").each(function() {
        $(this).attr("title", "");

        // for list_view header-date-format/position
        if (events[0] && !events[0].url.includes("ideal_calendar")) {
          $(this).text($(this).parent().find(".fc-list-day-side-text").text());
          $(this).parent().find(".fc-list-day-side-text").text("");
        }
      });
    },
    eventDragStart: function(info) {
      // show help-line for time-slot
      if (info.event.end) { // not in month-view
        const line = document.createElement("div");
        line.className = "drag-line";
        document.body.appendChild(line);
        info.el.setAttribute("data-line-id", "drag-line");
        document.addEventListener("mousemove", updateLinePosition);
      }
    },
    windowResize: function ($info) {
      $item.$calendar._changeCalendarView($info.view.type);
      $item.$calendar._checkButtonsVisibility();
      $item.$calendar.reload();
      $("[data-toggle=tooltip]").tooltip();

      // for list_view header-date-format/position
      if (!$item.$calendar.$extraOptions.urls.data.includes("ideal_calendar")) {
        $(".fc-list-day-text").each(function() {
          $(this).text($(this).parent().find(".fc-list-day-side-text").text());
          $(this).parent().find(".fc-list-day-side-text").text("");
        });
      }
    },
    eventDragStop: function(info) {
      // remove help-line for time-slot
      if (info.event.end) { // not in month-view
        const line = document.querySelector(".drag-line");
        if (line) {
          line.parentNode.removeChild(line);
        }
        document.removeEventListener("mousemove", updateLinePosition);
      }
    },
    eventDrop: function(info) {
      // after event has been dropped, store id, begin, end and filter values
      const id = info.event.id;
      let calendar_type = "";
      let fetch_url;
      if (info.event.url.includes("ideal_calendar")) {
        calendar_type = "ideal_calendar";
        fetch_url = "/chronos/ideal_calendar/update_dragged_ideal_event/";
      } else if (info.event.url.includes("calendar")) {
        calendar_type = "calendar";
        fetch_url = "/chronos/calendar/update_dragged_event/";
      }
      const begin = info.event.start.toUTCString();
      const data = {
        calendar_type: calendar_type,
        id: id,
        begin: begin,
        filters:
          {
            filter_cohorts: filter_el_cohorts.val(),
            filter_theme: filter_el_theme.val(),
            filter_persons: filter_el_persons.val(),
            filter_rooms: filter_el_rooms.val(),
            filter_course_number: filter_el_course_number.val(),
            filter_course_type: filter_el_course_type.val(),
            filter_modules: filter_el_modules.val(),
            filter_room_group: filter_el_room_group.val(),
          },
      };

      if (info.event.url.includes("ideal_calendar")) {
        data.ideal_semester_type = $("#id_ideal_semester_type_select").val();
      }

      // if end exists (not in month view), store end in data dict
      data.is_valid = true;
      if ((info.view.type === "timeGridWeek" | info.view.type === "timeGridDay") && info.event.end === null) {
        data.is_valid = false;
      }
      if (info.event.end) {
        data.end = info.event.end.toUTCString();
      }
      // show loader
      const check_calendar_button = $("#check_calendar_button");
      check_calendar_button.hide();
      $(".loader").show();

      // call the update view when an event has been dragged
      fetch(fetch_url, {
        method: "POST",
        headers: {
          "X-CSRFToken": getCSRFToken(),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: data }),
      })
        .then(response => response.json())
        .then(function (data) {
          // data contains all event_data that will be updated
          const event_data = data.event_data;
          if (event_data === "revert") {
            $("[data-toggle=tooltip]").tooltip("dispose").tooltip({ boundary: "window" });
            info.revert();
          } else {
            // for every event_data update title so that icons are refreshed
            $("[data-toggle=tooltip]").tooltip("dispose").tooltip({ boundary: "window" });
            for (let i = 0; i < event_data.length; i++) {
              const calendar_item = $item.$calendar.$api.getEventById(event_data[i].id);
              if (calendar_item) {
                if (calendar_item.extendedProps.has_warnings) {
                  calendar_item._def.ui.borderColor = event_data[i].borderColor;
                } else {
                  calendar_item._def.ui.borderColor = event_data[i].borderColor;
                }
                calendar_item.setProp("title", event_data[i].title);
              }
            }
          }
          // hide loader
          check_calendar_button.show();
          $(".loader").hide();
        })
        // in case error occurs revert drag and undo change
        .catch(function (error) {
          console.log(error);
          $("[data-toggle=tooltip]").tooltip("dispose").tooltip({ boundary: "window" });
          info.revert();
          // hide loader
          check_calendar_button.show();
          $(".loader").hide();
        });
    },
    viewDidMount: function(info) {
      const viewType = info.view.type;
      if ($item.$calendar) {
        $item.$calendar._updateEvents(viewType); // from x-django-fullcalendar
      }
      // disable prev-/next-buttons if listAll-view (in snapshots and for lecturers)
      if (viewType === "listAll") {
        $(".fc-prev-button").attr("disabled", "disabled");
        $(".fc-next-button").attr("disabled", "disabled");
        $(".fc-prev-button").addClass("fc-state-disabled");
        $(".fc-next-button").addClass("fc-state-disabled");
      } else {
        $(".fc-prev-button").removeAttr("disabled");
        $(".fc-next-button").removeAttr("disabled");
        $(".fc-prev-button").removeClass("fc-state-disabled");
        $(".fc-next-button").removeClass("fc-state-disabled");
      }
      $("[data-toggle=tooltip]").tooltip("dispose").tooltip({ boundary: "window" });
    },
    moreLinkClick: function (info) {
      // hack because we have no callback after the popover is shown
      setTimeout(() => {
        $("[data-toggle=tooltip]").tooltip("dispose").tooltip({ boundary: "window" });
      }, 100);
    },
  });
  // if "uuid" parameter in url -> add to uuid to calendar url
  const currentLocation = window.location;
  const urlParams = new URLSearchParams(currentLocation.search);
  if ($item.$calendar.$extraOptions.urls.data.indexOf("ideal_calendar") === -1) {
    $item.$calendar.$extraOptions.urls.data = "/chronos/calendar/data/events/" + urlParams.get("uuid") + "/";
  }
  $item.$calendar._updateEvents($item.$calendar.$api.view.type); // from x-django-fullcalendar
});

// update position of help-line for timeslot during drag/drop
function updateLinePosition() {
  const line = document.querySelector(".drag-line");
  const draggedEl = document.querySelector(".fc-event-dragging");
  if (line && draggedEl) {
    const calendarEl = document.querySelector(".fc-timegrid-slots");
    const timeColumnEl = calendarEl.querySelector(".fc-timegrid-slot-label-frame");

    line.style.left = timeColumnEl.getBoundingClientRect().left + "px";
    line.style.width = calendarEl.getBoundingClientRect().width + "px";
    line.style.top = draggedEl.getBoundingClientRect().top + "px";
  }
}

function getCSRFToken () {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = jQuery.trim(cookies[i]);
      if (cookie.substring(0, 10) === ("csrftoken" + "=")) {
        cookieValue = decodeURIComponent(cookie.substring(10));
        break;
      }
    }
  }
  return cookieValue;
}

// filter collapse
$body.on("click", "#filter_collapse_button", function () {
  const $calendar_filters = document.getElementById("calendar_filters");
  const calendar = document.querySelector("[data-calendar]").$calendar;

  if ($calendar_filters.classList.contains("d-none")) {
    $calendar_filters.classList.remove("d-none");
    $("#filter_collapse_button").text(gettext("Hide filters"));
    calendar.reload();
  } else {
    $calendar_filters.classList.add("d-none");
    $("#filter_collapse_button").text(gettext("Show filters"));
    calendar.reload();
  }
});

function calculate_week_number(date) {
  const first_jan = new Date(date.getYear(), 0, 1);
  const begin_date = new Date(date.getYear(), date.getMonth(), date.getDate());
  const dayOfYear = ((begin_date - first_jan + 86400000) / 86400000);
  return Math.ceil(dayOfYear / 7);
}

// filter calendar
function filter_calendar(el, filter) {
  const calendar = document.querySelector("[data-calendar]").$calendar;
  calendar.addExtraParameter("filter_" + filter, el.val());
  calendar._updateEvents(calendar.$api.view.type);
  $("[data-toggle=tooltip]").tooltip("dispose").tooltip({ boundary: "window" });
}

// filter course number
const filter_el_course_number = $("#id_calendarfilterform-course_number");
if (filter_el_course_number.length) {
  filter_el_course_number.on("input", function (event) {
    filter_calendar(filter_el_course_number, "course_number");
  });
  if (filter_el_course_number.val()) {
    filter_calendar(filter_el_course_number, "course_number");
  }
}

// filter theme
const filter_el_theme = $("#id_calendarfilterform-theme");
if (filter_el_theme.length) {
  filter_el_theme.on("input", function (event) {
    filter_calendar(filter_el_theme, "theme");
  });

  if (filter_el_theme.val() !== "") {
    filter_calendar(filter_el_theme, "theme");
  }
}

// filter cohorts by select
const filter_el_cohorts = $("#id_calendarfilterform-cohorts");
if (filter_el_cohorts.length) {
  filter_el_cohorts.change(function (event) {
    filter_calendar(filter_el_cohorts, "cohorts");
  });
  if (filter_el_cohorts.val().length) {
    filter_calendar(filter_el_cohorts, "cohorts");
  }
}

// filter modules by select
const filter_el_modules = $("#id_calendarfilterform-modules");
if (filter_el_modules.length) {
  filter_el_modules.change(function (event) {
    filter_calendar(filter_el_modules, "modules");
  });
  if (filter_el_modules.val().length !== 0) {
    filter_calendar(filter_el_modules, "modules");
  }
}

// filter persons by select
const filter_el_persons = $("#id_calendarfilterform-persons");
if (filter_el_persons.length) {
  filter_el_persons.change(function (event) {
    filter_calendar(filter_el_persons, "persons");
  });
  if (filter_el_persons.val().length !== 0) {
    filter_calendar(filter_el_persons, "persons");
  }
}

// filter rooms by select
const filter_el_rooms = $("#id_calendarfilterform-rooms");
if (filter_el_rooms.length) {
  filter_el_rooms.change(function (event) {
    filter_calendar(filter_el_rooms, "rooms");
  });
  if (filter_el_rooms.val().length !== 0) {
    filter_calendar(filter_el_rooms, "rooms");
  }
}

// filter course_type by select
const filter_el_course_type = $("#id_calendarfilterform-course_type");
if (filter_el_course_type.length) {
  filter_el_course_type.change(function (event) {
    filter_calendar(filter_el_course_type, "course_type");
  });
  if (filter_el_course_type.val()) {
    filter_calendar(filter_el_course_type, "course_type");
  }
}

// filter roomg_group by select
const filter_el_room_group = $("#id_calendarfilterform-room_group");
if (filter_el_room_group.length) {
  filter_el_room_group.change(function (event) {
    filter_calendar(filter_el_room_group, "room_group");
  });
  if (filter_el_room_group.val()) {
    filter_calendar(filter_el_room_group, "room_group");
  }
}

// filter ideal calendar by semester type
const ideal_semester_type = $("#id_ideal_semester_type_select");
if (ideal_semester_type.length) {
  ideal_semester_type.change(function (event) {
    filter_calendar(ideal_semester_type, "ideal_semester_type");
  });
  filter_calendar(ideal_semester_type, "ideal_semester_type");
}

function add_loading_listener () {
  const check_calendar_button = $("#check_calendar_button");
  check_calendar_button.off("click");

  check_calendar_button.on("click", function () {
    check_calendar_button.hide();
    $(".loader").show();
  });
}

add_loading_listener();

// #############################################################################
// CHART JS

const $charts = document.querySelectorAll("[data-chartjs]");

$charts.forEach(function ($element) {
  const $chartJS = new $x.ChartJS($element);
  $chartJS.init();
});

// ############################################################################
// Init DateTime

function init_datetime_fields (datetime_fields, $modal) {
  datetime_fields.each(function () {
    const elm = $(this);
    const settings = { readonly_element: false, ...elm.data("zebra") };
    elm.Zebra_DatePicker(settings);
  });
}


// #############################################################################
// MODAL

// re-enable all during modal-open disabled data-modal-links
function removeDisabledAfterModalOpened() {
  document.querySelectorAll("[data-modal-link][data-modal-link-disabled]").forEach(function ($el) {
    $el.classList.remove("disabled");
    $el.removeAttribute("data-modal-link-disabled");
  });
}

$x.beforeModalOpenDefault = function ($modal, $data, $el) {
  removeDisabledAfterModalOpened();

  if ($data.submit === "error") {
    if ($data.toaster) {
      $("body").toaster("updateToaster", $data.toaster);
    }
  }

  const check_calendar_button = $("#check_calendar_button");

  if ($modal.children(".check_calendar_modal").length > 0) {
    if (check_calendar_button.length > 0) {
      $(".loader").hide();
      check_calendar_button.show();
    }
  }

  const datetime_fields = $(".zebra-datepicker", $modal);
  if (datetime_fields.length > 0) {
    init_datetime_fields(datetime_fields, $modal);
  }
};

$x.onModalOpenDefault = function ($modal) {
  $("[autofocus]", $modal).focus();
  $("[data-toggle=tooltip]", $modal).tooltip("dispose").tooltip({ boundary: "window" });

  const $djangoSelect2 = $(".django-select2");

  if ($djangoSelect2.length) {
    // Init Select2 Fields in the modal dialog, only if there is any django-select2 field.
    $djangoSelect2.djangoSelect2({
      dropdownParent: $("#modal"),
    });

    // EditIdealDateForm -> date_type/exam_type
    const ideal_date_type = document.querySelector("#id_editidealdateform-date_type");
    const ideal_exam_type = document.querySelector("#id_editidealdateform-exam_type");
    const ideal_attempt = document.querySelector("#id_editidealdateform-attempt");
    const ideal_ignore_cohort_check = document.querySelector("#id_editidealdateform-duplicate_cohorts_check_ignored");
    const ideal_is_room_mandatory = document.querySelector("#id_editidealdateform-is_room_mandatory");

    // EditDateForm -> Exams
    if (ideal_date_type) {
      ideal_date_type.addEventListener("change", () => {
        const ideal_theme_label = document.querySelector("[for=id_editidealdateform-theme]");
        // if date_type is not "EXAM" -> set exam_type to "" (for field-dependencies)
        if (ideal_date_type.value !== "3") {
          ideal_exam_type.value = "";
          ideal_theme_label.textContent = gettext("Theme");
        } else {
          ideal_theme_label.textContent = gettext("Exam name");
        }
      });
      ideal_exam_type.addEventListener("change", () => {
        // if exam_type == "SAP" -> reset "is_room_mandatory" to None
        if (ideal_exam_type.value === "2") {
          ideal_is_room_mandatory.value = "unknown";
        }
      });
    }

    // attempts
    if (ideal_attempt) {
      ideal_attempt.addEventListener("change", () => {
        if (ideal_attempt.value >= "3") {
          ideal_ignore_cohort_check.checked = true;
        }
      });
    }

    // EditDateForm
    // set modal_init so lecturer-select2s are not re-initialized! (they are visible at the start)
    // otherwise focus-loss can occur when editing them
    const lecturer_select2 = document.querySelector("#id_editdateform-lecturer");
    if (lecturer_select2) {
      lecturer_select2.dataset.modal_init = true;
    }
    const further_lecturer_select2 = document.querySelector("#id_editdateform-further_lecturers");
    if (further_lecturer_select2) {
      further_lecturer_select2.dataset.modal_init = true;
    }

    // EditDateForm -> date_type/exam_type/examiner/exam_theme
    const date_type = document.querySelector("#id_editdateform-date_type");
    const exam_type = document.querySelector("#id_editdateform-exam_type");
    const examiners = document.querySelector("#id_editdateform-examiners");
    const exam_theme = document.querySelector("#id_editdateform-exam_theme");
    const exam_attempt = document.querySelector("#id_editdateform-attempt");
    const exam_ignore_cohort_check = document.querySelector("#id_editdateform-duplicate_cohorts_check_ignored");

    // EditDateForm -> Exams
    if (date_type) {
      date_type.addEventListener("change", () => {
        const theme_label = document.querySelector("[for=id_editdateform-theme]");
        // if date_type is not "EXAM" -> set exam_type to "" (for field-dependencies)
        if (date_type.value !== "3") {
          exam_type.value = "";
          theme_label.textContent = gettext("Theme/Title");
        } else {
          theme_label.textContent = gettext("Exam name");
        }
      });
    }

    // unselect examiners when exam_theme changes
    $(exam_theme).on("change", function() {
      $(examiners).val(null).trigger("change");
    });

    // get all examiners for selected exam_theme
    if (examiners) {
      $(examiners).select2({
        width: "100%",
        dropdownParent: $("#modal"),
        ajax: {
          delay: 250,
          url: examiners.dataset.select2Url,
          dataType: "json",
          data: function(params) {
            return {
              search: params.term,
              exam_theme: exam_theme.value,
            };
          },
          results: function(data) {
            return { results: data };
          },
          minimumResultsForSearch: 0,
        },
      });
    }

    // attempts
    if (exam_attempt) {
      exam_attempt.addEventListener("change", () => {
        if (exam_attempt.value >= "3") {
          exam_ignore_cohort_check.checked = true;
        }
      });
    }

    // EditPersonForm -> department/institution selection
    const department_select2 = document.querySelector("#id_editpersonform-department");
    const institution_select2 = document.querySelector("#id_editpersonform-institution");

    // unselect department when institution changes
    $(institution_select2).on("change", function() {
      $(department_select2).val(null).trigger("change");
    });

    // get all departments for selected institution
    if (department_select2) {
      $(department_select2).select2({
        dropdownParent: $("#modal"),
        ajax: {
          delay: 250,
          url: department_select2.dataset.select2Url,
          dataType: "json",
          data: function(params) {
            return {
              search: params.term,
              institution: document.querySelector("#id_editpersonform-institution").value,
            };
          },
          results: function(data) {
            return { results: data };
          },
          minimumResultsForSearch: 0,
        },
      });
    }
  }

  // AKNumberForm -> la-type infos
  const ta_type = document.querySelector("#id_edittainfosform-teaching_assignment_type");
  const working_time = document.querySelector("#id_edittainfosform-working_time");
  const valid_from_semester = document.querySelector("#id_edittainfosform-valid_from_semester");
  const sec_ta_type = document.querySelector("#id_edittainfosform-teaching_assignment_type_secondary");

  if (ta_type && working_time) {
    getTAInfos(true);

    ta_type.addEventListener("change", getTAInfos);
  }

  function getTAInfos (init) {
    const ak_number = document.querySelector("#id_edittainfosform-ak_number");
    let sec_ta_type_value = sec_ta_type.value;
    if (!sec_ta_type_value) {
      sec_ta_type_value = "-";
    }
    fetch(ta_type.dataset.url + (ak_number.value ? ak_number.value : "-") + "/" + (ta_type.value ? ta_type.value : 0) + "/" + working_time.value + "/" + (valid_from_semester.value ? valid_from_semester.value : "-") + "/" + sec_ta_type_value + "/", {
      method: "GET",
    }).then(
      response => response.json()
    ).then(
      data => {
        Object.entries(data).forEach((el) => {
          if (el[0] === "secondary_ta_options") {
            const secondary_la_type = document.querySelector("#id_edittainfosform-teaching_assignment_type_secondary");
            secondary_la_type.innerHTML = "";
            if (el[1].length === 1) {
              secondary_la_type.disabled = true;
            } else {
              secondary_la_type.removeAttribute("disabled");
            }
            el[1].forEach(option => { secondary_la_type.innerHTML += option; });
          } else if (init === true) { // only update help-text on form load
            $("#id_edittainfosform-" + el[0]).nextAll(".text-muted").text(el[1]);
          }
        });
      }
    ).catch(
      error => console.log("la-infos error", error)
    );
  }

  if (document.querySelector("[data-calendar]")) {
    const calendar = document.querySelector("[data-calendar]").$calendar.$api;

    $(".check_calendar_link").click(function () {
      calendar.changeView("timeGridWeek", $(this).data("goToCalendarWeek"));
      $modal.modal("hide");
    });

    $("#id_semester_type_check_calendar_modal").change(function () {
      document.getElementById("id_check_calendar_warnigs").innerHTML = "<div></div>";
      $("#id_semester_type_check_calendar_modal").hide();
      $("#id_semester_check_calendar_modal_loader").show();
      fetch("/chronos/calendar/check/", {
        method: "POST",
        headers: {
          "X-CSRFToken": getCSRFToken(),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ semester_type: this.value }),
      })
        .then(response => response.json())
        .then(function (data) {
          document.getElementById("id_check_calendar_warnigs").innerHTML = data.template;
          $("#id_semester_type_check_calendar_modal").show();
          $("#id_semester_check_calendar_modal_loader").hide();
          // add listener for check_calendar_link to enable jumping to date in calendar
          $(".check_calendar_link").click(function () {
            calendar.changeView("timeGridWeek", $(this).data("goToCalendarWeek"));
            $modal.modal("hide");
          });
        });
    });
  }

  // "select all" for snapshot-form
  function get_and_set_snapshot_lecturers(cb, sel_selector, data) {
    fetch("affected/lecturer/", {
      method: "POST",
      headers: {
        "X-CSRFToken": getCSRFToken(),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(function (data) {
        console.log("checked1", $(cb).prop("checked"));
        for (const [key, value] of Object.entries(data)) {
          const selected_values = $(sel_selector).val();
          if ($(cb).prop("checked")) {
            if ($(sel_selector + " option[value=" + key + "]").length === 0) {
              $(sel_selector).append(new Option(value, key, false, true));
            } else {
              selected_values.push(key);
              $(sel_selector).val(selected_values);
            }
          } else {
            const index = selected_values.indexOf(key);
            if (index >= 0) {
              selected_values.splice(selected_values.indexOf(key), 1);
              $(sel_selector).val(selected_values);
            }
          }
          $(sel_selector).trigger("change");
        }
      });
  }
  $("#id_addsnapshotform-lecturers_valid_all_affected").change(function() {
    const snapshot_id = $(this).attr("data-snapshot-id");
    const data = {
      snapshot_id: snapshot_id,
      semester: $("#id_addsnapshotform-semester").val(),
      cohorts: $("#id_addsnapshotform-cohorts").val(),
      modules: $("#id_addsnapshotform-modules").val(),
      // old_modules: old_module_val,
      // old_cohorts: old_cohort_val,
      // cb: "cb_valid",
    };
    // get_and_set_changed_snapshot_lecturers(data);
    get_and_set_snapshot_lecturers(this, "[name=addsnapshotform-lecturers_valid]", data);
  });
  $("#id_addsnapshotform-lecturers_email_all_affected").change(function() {
    const snapshot_id = $(this).attr("data-snapshot-id");
    const data = {
      snapshot_id: snapshot_id,
      semester: $("#id_addsnapshotform-semester").val(),
      cohorts: $("#id_addsnapshotform-cohorts").val(),
      modules: $("#id_addsnapshotform-modules").val(),
    };
    // if (!$(this).prop("checked")) {
    //   // get_and_set_snapshot_lecturers(this, "[name=addsnapshotform-lecturers_email]", data);
    //   data.old_modules = $("#id_addsnapshotform-cohorts").val();
    //   data.old_cohorts = $("#id_addsnapshotform-modules").val();
    //   data.cb = "cb_email";
    // // } else {
    // }
    // console.log("data", data, old_module_val);
    // get_and_set_changed_snapshot_lecturers(data, data.cb);
    get_and_set_snapshot_lecturers(this, "[name=addsnapshotform-lecturers_email]", data);
  });
  $("#id_editsnapshotform-lecturers_valid_all_affected").change(function() {
    const snapshot_id = $(this).attr("data-snapshot-id");
    const data = {
      snapshot_id: snapshot_id,
    };
    get_and_set_snapshot_lecturers(this, "[name=editsnapshotform-lecturers_valid]", data);
  });
  $("#id_editsnapshotform-lecturers_email_all_affected").change(function() {
    const snapshot_id = $(this).attr("data-snapshot-id");
    const data = {
      snapshot_id: snapshot_id,
    };
    get_and_set_snapshot_lecturers(this, "[name=editsnapshotform-lecturers_email]", data);
  });

  const $formDefaults = $x.initFormDefaults($modal);

  // Validation

  $("[data-form]", $modal).formValidation({
    beforeSubmit: function () {
      $formDefaults.ajaxUpload.reset();
    },
    afterSubmit: function ($xhr, $form, $data) {
      if ($data.submit === "success") {
        $modal.modal("hide");

        if ($data.redirect) {
          checkRedirect($data);
        } else {
          $x.replaceHtml($data);

          if ($data.toaster) {
            $body.toaster("updateToaster", $data.toaster);
          }

          $dataTables.forEach(function ($item) {
            $item.$datatable.reload();
          });

          $calendars.forEach(function ($item) {
            $item.$calendar.$api.refetchEvents();
          });
        }
      }
    },
  });

  // Wizard

  $("[data-form-wizard]", $modal).formWizard();
};

$x.onModalCloseDefault = function ($modal) {
  // remove all backdrops if multiple modals are opened (boostrap adds multiple modal-backdrops)
  document.querySelectorAll(".modal-backdrop").forEach(function ($el) {
    $el.remove();
  });

  if ($(".django-select2").length) {
    // Init Select2 Fields again to remove  in the modal dialog, only if there is any django-select2 field.
    $(".django-select2").djangoSelect2({
    });
  }
};

$x.delegateEvent.on(document, "click", "[data-modal-link]", function (e) {
  e.preventDefault();

  // set all data-modal-links (which are not already disabled) disabled -> so only one modal can be opened at a time
  document.querySelectorAll("[data-modal-link]:not(.disabled):not([disabled])").forEach(function ($el) {
    $el.setAttribute("data-modal-link-disabled", "data-modal-link-disabled");
    $el.classList.add("disabled"); // only allow data-modal-link to be clicked once until modal opened
  });

  $x.modal.open(this.href, {
    onModalOpen: $x.onModalOpenDefault,
    beforeModalOpen: $x.beforeModalOpenDefault,
    onModalClose: $x.onModalCloseDefault,
  }, this);
});

// #############################################################################
// DOWNLOAD BLOB

$x.delegateEvent.on(document, "click", "[data-download]", function (e) {
  e.preventDefault();

  const $downloadBlob = new $x.DownloadBlob({
    onDownloadStarted: function ($data) {
      $body.toaster("updateToaster", $data.toaster);

      $dataTables.forEach(function ($item) {
        $item.$datatable.reload();
      });
    },
  });

  $downloadBlob.download(this.href);
});

// #############################################################################
// CLIPBOARD

$body.clipBoard({
  selector: "[data-clipboard]",
});

// #############################################################################
// TOASTER

$body.toaster({
  selector: "[data-toaster]",
});

// #############################################################################
// AUTO UPDATE HTML CONTENT

// TODO: Demo erstellen

$body.autoUpdateHtmlContent({
  selector: "[data-update-html-content]",
});

// #############################################################################
// handle logout on redirect to shibboleth
// $(document).ajaxError(function (event, jqxhr, settings, thrownError) {
//   $.get("/Shibboleth.sso/Session", function(data) {
//     if (data.search("A valid session was not found.") > 0) {
//       window.location.reload();
//     }
//   });
// });

$("#id_ideal_semester_type_select").change(function() {
  const old_href = document.getElementById("check_calendar_button").getAttribute("href");
  let new_href = old_href.substring(0, old_href.length - 2);
  const new_semester = document.getElementById("id_ideal_semester_type_select").value;
  new_href = new_href + new_semester + "/";
  document.getElementById("check_calendar_button").href = new_href;
  console.log(new_href);
});

$("#check_calendar_button").click(async function(e) {
  if (!this.href.includes("check_ideal")) {
    e.preventDefault();
    const calendar = document.querySelector("[data-calendar]").$calendar.$api.currentData.eventSources;
    let start_date;
    for (var key in calendar) {
      start_date = calendar[key].fetchRange.start;
    }
    const that = this;

    fetch("/chronos/calendar/calculate_current_semester/", {
      method: "POST",
      headers: {
        "X-CSRFToken": getCSRFToken(),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: start_date }),
    })
      .then(response => response.json())
      .then(function (data) {
        const old_href = document.getElementById("check_calendar_button").getAttribute("href");
        let new_href = old_href.substring(0, old_href.length - 6);
        new_href = new_href + data.semester + "/";
        document.getElementById("check_calendar_button").href = new_href;
        $x.modal.open(that.href, {
          onModalOpen: $x.onModalOpenDefault,
          beforeModalOpen: $x.beforeModalOpenDefault,
          onModalClose: $x.onModalCloseDefault,
        });
      });
  }
});
